
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import UserID from '@/model/user/UserID';
import Accesskey from '@/model/accessKeys/Accesskey';
import UserRegisterApi from '@/module/api/UserRegistApi';

Component.registerHooks([
  'beforeRouteEnter'
]);
@Component({
  components: {
  }
})
export default class Register extends Vue {
  accessKey: Accesskey = new Accesskey();
  userId: UserID = new UserID();
  setMessage = '';

  beforeRouteEnter(to: Route, from: Route, next: any): void {
    next((vm: Register) => vm.initialize(to));
  }
  initialize(to: Route): void {
    this.accessKey = new Accesskey(to.params.accessKey);
    this.userId = new UserID(to.params.userId);
  }

  mounted():void{
    UserRegisterApi.activate(this.accessKey, this.userId)
      .then(() => {
        this.setMessage = '登録を受け付けました。承認され次第メールで通知されます。承認後ログイン可能です。';
      }).catch(() => {
        this.setMessage = 'アクセスキーが正しくないか、有効期限切れです。';
      });
  }
}
